<template>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Item Penomoran</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-item-penomoran">Item Penomoran
                    </router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }}
                  </li>
                </ol>
              </nav>
            </div>
            <div>
              <button class="btn btn-save" :disabled="isSubmit" type="submit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan
              </button>
              <button class="btn btn-blue ml-2" type="button" @click="onSync" v-if="enableSync" :disabled="isSubmit">
                <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                Simpan & Sync
              </button>
            </div>
          </div>

          <div class="card info-detail">
            <div class="row">
              <div class="col-12">
                <div class="title">Data Item Penomoran</div>
                <div class="sub-title">
                  {{ labelPage == "Tambah" ? "Tambah" : "Edit" }} informasi Item
                  Penomoran yang diperlukan
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6">
                <div class="form-group">
                  <label for="nama">Nama : </label>
                  <input type="text" v-model="formData.nama" @keyup="formChange('nama')"
                    :class="{ 'is-invalid': formError && formError.nama }" class="form-control" id="nama"
                    placeholder="Masukkan Nama Penomoran" />
                  <div class="form-error" v-if="formError && formError.nama">
                    {{ formError.nama }}
                  </div>
                </div>

                <div class="form-group">
                  <label for="id_tipe">Kategori Penomoran :</label>
                  <Select2 v-model="formData.id_tipe" :class="{ 'is-invalid': formError && formError.id_tipe }"
                    :options="optionCatgeoryNumbering" placeholder="Pilih Kategori Penomoran" disabled="true"
                    :settings="{ settingOption: value, settingOption: value }" @change="formChange('id_tipe')"
                    @select="formChange('id_tipe')" />
                  <div class="form-error" v-if="formError && formError.id_tipe">
                    {{ formError.id_tipe }}
                  </div>
                </div>
                <!-- 
                                <div class="form-group">
                                    <label for="divisi">Departement :</label>
                                    <Select2 v-model="formData.id_divisi" :class="{'is-invalid': formError && formError.id_divisi}" :options="optionDepartement"
                                        placeholder="Pilih Departement"
                                        @change="formChange('id_divisi')" @select="formChange('id_divisi')" />
                                        <div class="form-error" v-if="formError && formError.id_divisi">{{formError.id_divisi}}</div>
                                </div> -->
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label for="digit">Jumlah Digit Counter : </label>
                  <input type="text" :class="{ 'is-invalid': formError && formError.nilai }"
                    @keyup="formChange('nilai')" v-model="formData.nilai" class="form-control" id="digit"
                    placeholder="Masukkan Jumlah Digit Counter" />
                  <div class="form-error" v-if="formError && formError.nilai">
                    {{ formError.nilai }}
                  </div>
                </div>
                <div class="form-group">
                  <label for="inputAddress2">Komponen Penomoran :</label>

                  <div class="d-flex">
                    <Select2 v-model="komponenNumbering" :options="OptionNumbering" style="width: 100%"
                      placeholder="Pilih Komponen Penomoran" :settings="{ settingOption: value, settingOption: value }"
                      @change="selectKomponen($event)" @select="selectKomponen($event)" />
                    <button class="btn-add-komponen" type="button" v-on:click="addComponent">
                      +
                    </button>
                  </div>
                  <input type="text" v-model="inputText" :class="{ 'is-invalid': errorInputText }" class="form-control"
                    @keypress="checkKey($event)" v-if="isShowText" id="input-text" placeholder="Masukkan Text" />
                  <div class="input-container" v-if="resultPatternTags.length > 0">
                    <ul class="list">
                      <li v-for="(value, index) in resultPatternTags" :key="index" style="
                          margin-right: 10px;
                          background-color: #68caf8;
                          color: #fff;
                          padding: 5px 10px;
                          border-radius: 3px;
                          margin: 5px 10px 5px 0;
                        " id="">
                        {{ value.text }}
                        <!-- v-if="!value.render" -->
                        <span class="deleteTags" style="cursor: pointer" @click="deleteTags(index)"><svg width="24"
                            height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M15.9436 7.35355C15.7483 7.15829 15.4317 7.15829 15.2364 7.35355L12.3536 10.2364C12.1583 10.4317 11.8417 10.4317 11.6464 10.2364L8.76355 7.35355C8.56829 7.15829 8.25171 7.15829 8.05645 7.35355L7.35355 8.05645C7.15829 8.25171 7.15829 8.56829 7.35355 8.76355L10.2364 11.6464C10.4317 11.8417 10.4317 12.1583 10.2364 12.3536L7.35355 15.2364C7.15829 15.4317 7.15829 15.7483 7.35355 15.9436L8.05645 16.6464C8.25171 16.8417 8.56829 16.8417 8.76355 16.6464L11.6464 13.7636C11.8417 13.5683 12.1583 13.5683 12.3536 13.7636L15.2364 16.6464C15.4317 16.8417 15.7483 16.8417 15.9436 16.6464L16.6464 15.9436C16.8417 15.7483 16.8417 15.4317 16.6464 15.2364L13.7636 12.3536C13.5683 12.1583 13.5683 11.8417 13.7636 11.6464L16.6464 8.76355C16.8417 8.56829 16.8417 8.25171 16.6464 8.05645L15.9436 7.35355Z"
                              fill="white"></path>
                          </svg>
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div class="form-error mt-2" v-if="formError && formError.pola">
                    {{ formError.pola }}
                  </div>

                  <div class="d-flex mt-2">
                    <label :style="{ fontSize: '13px', fontWeight: '600' }">Contoh Penomoran</label>
                    <div class="ml-3" :style="{ fontWeight: '800' }">
                      {{ resultPattern ? resultPattern : "-" }}
                    </div>
                  </div>
                  <div class="alert alert-danger" v-if="alert.err">
                    <ul class="pl-3 mb-0" :style="{ fontSize: '13px' }">
                      <div v-for="(value, index) in alert.list" :key="index">
                        <li :style="{ lineHeight: '18px' }" v-if="value.show">
                          {{ value.msg }}
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
// import FooterDashboard from '../src/components/Footer.vue'
import Select2 from "vue3-select2-component";
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import { loadScript } from "vue-plugin-load-script";

import $ from "jquery";
import { get_ListDivisi, get_ListTransaksiTipe } from "../../../actions/master";
import { checkRules, cksClient, showAlert, checkApp } from "../../../helper";
import {
  get_CompanySettingDetail,
  post_CompanySettingSave,
} from "../../../actions/setting";
loadScript("/assets/js/stisla.js");
loadScript("/assets/js/scripts.js");
loadScript("/assets/js/custom.js");
loadScript("/assets/js/page/index.js");
const moment = require("moment");
export default {
  name: "DashboardLayout",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
  },

  data() {
    return {
      enableSync: checkApp("rawuh") ? true : false,
      OptionNumbering: [
        {
          text: "Tahun",
          id: "YEAR",
          value_item: "{{YEAR}}",
        },
        {
          text: "Tahun Bergabung",
          id: "YEAR-JOINED",
          value_item: "{{YEAR-JOINED}}",
        },
        {
          text: "Bulan",
          id: "MONTH",
          value_item: "{{MONTH}}",
        },
        {
          text: "Bulan Bergabung",
          id: "MONTH-JOINED",
          value_item: "{{MONTH-JOINED}}",
        },
        {
          text: "Bulan Romawi",
          id: "MONTH-ROMAWI",
          value_item: "{{MONTH-ROMAWI}}",
        },
        {
          text: "Tanggal",
          id: "DATE",
          value_item: "{{DATE}}",
        },
        {
          text: "Tanggal Bergabung",
          id: "DATE-JOINED",
          value_item: "{{DATE-JOINED}}",
        },
        {
          text: "Counter",
          id: "COUNTER",
          value_item: "{{COUNTER}}",
        },
        {
          text: "Text/Pemisah",
          id: "TEXT",
          value_item: "",
        },
        {
          text: "ID Karyawan",
          id: "ID-EMPLOYEE",
          value_item: "{{ID-EMPLOYEE}}",
        },
        {
          text: 'Kode Jabatan',
          id: "POSITION-CODE",
          value_item: "{{POSITION-CODE}}",
        },
        {
          text: "Kode Perusahaan",
          id: "CODE-COMPANY",
          value_item: "{{CODE-COMPANY}}",
        },
        {
          text: "Kode Vendor",
          id: "VENDOR-CODE",
          value_item: "{{VENDOR-CODE}}",
        },
        {
          text: 'Kode Project',
          id: "PROJECT-CODE",
          value_item: '{{PROJECT-CODE}}'
        },
        {
          text: "Tahun vendor Bergabung",
          id: "VENDOR-YEAR",
          value_item: "{{VENDOR-YEAR}}"
        },
        {
          text: "Vendor Kategori",
          id: "VENDOR-CATEGORY",
          value_item: "{{VENDOR-CATEGORY}}"
        }
      ],
      labelPage: this.$route.params.id ? "Detail" : "Tambah",
      id_item: this.$route.params.id ? this.$route.params.id : "",
      formData: {
        id: this.$route.params.id ? this.$route.params.id : "",
        id_company: cksClient().get("_account").id_company,
        nama: "",
        id_tipe: "",
        id_divisi: "",
        nilai: 3,
        pola: "",
        isSync: false,
      },
      formError: [],
      rules: {
        nama: {
          required: true,
        },
        id_tipe: {
          required: true,
        },
        nilai: {
          required: true,
          min: 3,
        },
        pola: {
          required: true,
          msg: "Pola harus anda isi",
        },
      },
      alert: {
        list: [
          {
            msg: "Tambahkan komponen [YEAR] dan [MONTH] untuk menghindari nomor bentrok saat Reset Penomoran terjadi.",
            show: false,
          },
          {
            msg: "Tambahkan komponen [COUNTER] agar nomor berurut maju.",
            show: false,
          },
        ],
        err: false,
      },
      komponenNumbering: "",
      optionDepartement: [],
      optionCatgeoryNumbering: [],
      resultPatternTags: [
        {
          id: Math.floor(Math.random() * 100000 + 1),
          text: "Counter",
          value: "{{COUNTER}}",
          render: true,
        },
      ],
      resultPattern: "",
      id_company: cksClient().get("_account").id_company,
      isShowText: false,
      errorInputText: false,
      inputText: "",
      isSubmit: false,
      pattern: {
        "{{YEAR}}": moment().format("YYYY"),
        "{{YEAR-JOINED}}": '2022',
        "{{MONTH}}": moment().format("MM"),
        "{{MONTH-JOINED}}": '01',
        "{{DATE}}": moment().format("DD"),
        "{{DATE-JOINED}}": '10',
        "{{MONTH-ROMAWI}}": this.getMonthRomawi(moment().format("MM").toString()),
        "{{ID-EMPLOYEE}}": "",
        "{{CODE-COMPANY}}": cksClient().get("_account").code_company,
        "{{POSITION-CODE}}": cksClient().get("_account").kode_jabatan,
        "{{VENDOR-CODE}}": "2019.Z001.0001",
        "{{PROJECT-CODE}}": '',
        "{{VENDOR-YEAR}}": "2019",
        "{{VENDOR-CATEGORY}}": "Z001",
      },
    };
  },
  created() {
    this.getTransaksiTipe();
    this.getDivisi();
    if (this.id_item) {
      this.getItem();
    }
  },
  methods: {
    removeObjNumbering() {
      // console.log(this.OptionNumbering);
      // var vendorIndex = this.OptionNumbering.indexOf((e) => e.id == "VENDOR-CODE");
      if (this.formData.id_tipe != 35 && this.formData.id_tipe != 26) {
        var vendorIndex = this.OptionNumbering.findIndex((e) => e.id == "VENDOR-CODE");
        if (vendorIndex >= 0) {
          this.OptionNumbering.splice(vendorIndex, 1)
        }
      }
      
      if (this.formData.id_tipe != 35 && this.formData.id_tipe != 36) {
        var projectIndex = this.OptionNumbering.findIndex((e) => e.id == "PROJECT-CODE");
        if (projectIndex >= 0) {
          this.OptionNumbering.splice(projectIndex, 1);
        }
      }

      if (this.formData.id_tipe != 6) {
        var vendorCategoryIndex = this.OptionNumbering.findIndex((e) => e.id == "VENDOR-CATEGORY");
        if (vendorCategoryIndex >= 0) {
          this.OptionNumbering.splice(vendorCategoryIndex, 1);
        }
      }

      if (this.formData.id_tipe != 6) {
        var vendorYearIndex = this.OptionNumbering.findIndex((e) => e.id == "VENDOR-YEAR");
        if (vendorYearIndex >= 0) {
          this.OptionNumbering.splice(vendorYearIndex, 1);
        }
      }

      if (this.formData.id_tipe != 20) {
        var yearJoined = this.OptionNumbering.findIndex((e) => e.id == "YEAR-JOINED");
        if (yearJoined >= 0) {
          this.OptionNumbering.splice(yearJoined, 1);
        }

        var monthJoined = this.OptionNumbering.findIndex((e) => e.id == "MONTH-JOINED");
        if (monthJoined >= 0) {
          this.OptionNumbering.splice(monthJoined, 1);
        }
        var dateJoined = this.OptionNumbering.findIndex((e) => e.id == "DATE-JOINED");
        if (dateJoined >= 0) {
          this.OptionNumbering.splice(dateJoined, 1);
        }
      }
    },
    getMonthRomawi(month) {
      switch (month) {
        case "01":
          return "I";

        case "02":
          return "II";

        case "03":
          return "III";

        case "04":
          return "IV";

        case "05":
          return "V";

        case "06":
          return "VI";

        case "07":
          return "VII";

        case "08":
          return "VIII";

        case "09":
          return "IX";

        case "10":
          return "X";

        case "11":
          return "XI";

        case "12":
          return "XII";
        default:
          break;
      }
    },
    getItem() {
      this.resultPatternTags = [];
      get_CompanySettingDetail(this.id_item, (res) => {
        var data = res.data;
        if (data) {
          this.formData = { ...this.formData, ...data };
          this.removeObjNumbering()
          delete this.formData.created_at;
          delete this.formData.updated_at;
          if (
            [18, 19, 20].indexOf(this.formData.id_tipe) < 0 &&
            this.enableSync
          ) {
            this.enableSync = false;
          }
          this.pattern["{{ID-EMPLOYEE}}"] = res.id_karyawan;
          var pola = JSON.parse(data.pola);
          var length = 0;
          for (const key in pola) {
            length = key;
            const element = pola[key];
            var text = "";
            var item = this.OptionNumbering.find((e) => e.value_item == element);
            if (item && item.text) {
              text = item.text;
            } else {
              text = element;
            }
            this.resultPatternTags.push({
              id: Math.floor(Math.random() * 100000 + 1),
              text: text,
              value: element,
              render: length === key + 1 ? true : false,
            });
          }
          this.resultPatternTags[length].render = true;
          this.setPattern();
        } else {
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Item Penomoran tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({ name: "NumberingItems" });
            },
          });
        }
      }, (e) => {
        console.log(e);
      });
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        this.optionDepartement = res.list;
      });
    },
    getTransaksiTipe() {
      get_ListTransaksiTipe({
        id_company: this.id_company
      }, (res) => {
        this.pattern["{{PROJECT-CODE}}"] = res.project_number;
        this.setPattern();
        this.optionCatgeoryNumbering = res.list;
      });
    },
    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var $state = $(
        "<div>" +
        state.text +
        '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
        state.no +
        "<div>" +
        state.type +
        "</div></div></div>"
      );
      return $state;
    },
    selectKomponen($el) {
      this.isShowText = $el.id == "TEXT" ? true : false;
    },

    addComponent() {
      if (this.komponenNumbering) {
        var rondom = Math.floor(Math.random() * 100000 + 1);
        var type = this.komponenNumbering;
        if (type == "TEXT") {
          type = this.inputText;
          this.errorInputText = false;
          if (!type) {
            this.errorInputText = true;
            return false;
          }
        }
        var data = this.OptionNumbering.filter((option) => {
          if (option.id == this.komponenNumbering) {
            return option;
          }
          return "";
        });

        // this.resultPatternTags.splice(this.resultPatternTags.length - 1, 0, {
        //   id: rondom,
        //   text:
        //     this.komponenNumbering == "TEXT" ? this.inputText : data[0].text,
        //   value: this.komponenNumbering != "TEXT" ? data[0].value_item : type,
        // });

        this.resultPatternTags.push({
          id: rondom,
          text:
            this.komponenNumbering == "TEXT" ? this.inputText : data[0].text,
          value: this.komponenNumbering != "TEXT" ? data[0].value_item : type,
        });
        this.setPattern();
      }
    },
    async formChange(key) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      if (key === "nilai") {
        this.setPattern();
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },
    deleteTags(index) {
      // delete this.resultPatternTags[index]
      this.resultPatternTags.splice(index, 1);
      this.setPattern();
    },

    checkKey(evt) {
      var arr = [47, 92, 61, 96, 91, 93, 39, 59];
      var keyCode = evt.keyCode;
      if (arr.includes(keyCode)) {
        evt.preventDefault();
      }
    },

    sprintf(panjang, counter) {
      var counter_fix = "";
      var j = parseInt(panjang) - parseInt(counter.toString().length);
      for (let i = 0; i < j; i++) {
        counter_fix += "0";
      }
      counter_fix += `${counter}`;
      return counter_fix;
    },
    async setPattern() {
      this.resultPattern = "";
      var fixPattern = [];
      if (this.resultPatternTags.length > 0) {
        var bulan = false;
        var year = false;
        var counter = false;
        for (const key in this.resultPatternTags) {
          const element = this.resultPatternTags[key];
          if (element.value === "{{YEAR}}" && !year) {
            year = true;
          }
          if (element.value === "{{MONTH}}" && !bulan) {
            bulan = true;
          }
          if (element.value === "{{COUNTER}}" && !counter) {
            counter = true;
          }
          fixPattern.push(element.value);
          if (!this.pattern[element.value] && element.value != "{{COUNTER}}") {
            this.resultPattern += element.value;
          } else if (element.value === "{{COUNTER}}") {
            var panjang = this.formData.nilai ? this.formData.nilai : 3;
            this.resultPattern += this.sprintf(panjang, 1);
          } else {
            this.resultPattern += this.pattern[element.value];
          }
        }
        var error = !counter ? true : false;
        this.alert.err = error;
        // this.alert.list[0].show = !bulan || !year ? true : false;
        this.alert.list[1].show = !counter ? true : false;
      }
      this.formData.pola =
        fixPattern.length > 0 ? JSON.stringify(fixPattern) : "";
      this.formChange("pola");
    },

    postData() {
      this.isSubmit = true;
      post_CompanySettingSave(
        this.formData,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Penomoran berhasil ditambahkan"
              : "Penomoran berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              this.$router.push({ name: "NumberingItems" });
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "GAGAL!",
            msg: "Terjadi Kesalahan, silakan ulagin kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async onSubmit() {
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && !this.alert.err) {
        this.formData.isSync = false;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },

    async onSync() {
      for (const key in this.rules) {
        if (this.rules[key]) {
          this.rules[key].changed = true;
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && !this.alert.err) {
        this.formData.isSync = true;
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      }
    },
  },
  watch: {
    id_item: function (newVal) {
      this.id_item = newVal;
      this.formData.id = newVal;
      this.getItem();
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css">

</style>

<style scoped src="../../../assets/css/custom.css">

</style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.list {
  list-style: none;
  display: flex;
  padding: 0px;
  margin-bottom: 0px;
  flex-wrap: wrap;
}

.result-component {
  list-style: none;
  display: flex;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 20px;
}

#input-text {
  margin-top: 20px;
}

.input-container {
  font-size: 14px;
  padding: 10px 15px;
  background-color: transparent;
  border: #eceff1 1px solid;
  margin-top: 20px;
  min-height: 64px;
}

.btn-add-komponen {
  background-color: #3cb778;
  color: #fff;
  border: none;
  padding: 0 15px;
  font-size: 25px;
  margin-left: 15px;
  border-radius: 5px;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
</style>
